import React from "react";
import "./PageTitle.scss";

const PageTitle = ({ title }) => {
  return (
    <div className='title__wrapper'>
      <div className='title__wrapper-container'>
        <h1 className='title'>{title}</h1>
      </div>
    </div>
  );
};

export default PageTitle;
