import React from "react";
import { Link } from "react-router-dom";

function FooterNavigation() {
  return (
    <nav className='footer__quicklinks__nav'>
      <ul className='footer__quicklinks__list'>
        <li className='footer__quicklinks__list__item'>
          <Link to='/about-us' className='footer__quicklinks__link'>
            About us
          </Link>
        </li>
        <li className='footer__quicklinks__list__item'>
          <Link to='/contact-us' className='footer__quicklinks__link'>
            Contact us
          </Link>
        </li>
        <li className='footer__quicklinks__list__item'>
          <Link to='/privacy-policy' className='footer__quicklinks__link'>
            Privacy Policy
          </Link>
        </li>
        <li className='footer__quicklinks__list__item'>
          <Link to='/terms-and-conditions' className='footer__quicklinks__link'>
            Terms & Conditions
          </Link>
        </li>
        <li className='footer__quicklinks__list__item'>
          <Link to='/cookie-policy' className='footer__quicklinks__link'>
            Cookie Policy
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default FooterNavigation;
