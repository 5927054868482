import { useState, useEffect } from "react";
import Container from "../../layout/container/Container";
import Navigation from "../navigation/Navigation";
import throttle from "lodash/throttle";

import "./Header.scss";
import { Link } from "react-router-dom";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = throttle(() => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }, 100); // Change the throttle time to whatever you prefer

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleHamburgerClasses = (open) => {
    const hamburgerBars = document.querySelectorAll(".header__hamburger__bar");

    hamburgerBars.forEach((bar, index) => {
      bar.classList.toggle(
        `header__hamburger__bar_${index + 1}-transform`,
        open
      );
    });
  };

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
    toggleHamburgerClasses(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
    toggleHamburgerClasses(false);
  };

  return (
    <header className={`header ${isScrolled ? "scrolled" : ""}`}>
      <div
        className={`header__bg ${menuOpen ? "navigation_open" : ""}`}
        style={{ backdropFilter: isScrolled ? "none" : "blur(10px)" }}>
        <Container width='large'>
          <div className='header__wrapper'>
            <div className='header__group'>
              <div className='header__logo_wrapper'>
                <Link to='/' onClick={closeMenu} className='header__logo__link'>
                  <img
                    src={`${process.env.PUBLIC_URL}/logo.svg`}
                    alt='Red Lemon Events logo. Square in shape, it comprises of text overlay on top of a rounded lemon shape.'
                    width={146}
                    className='header__logo'
                  />
                </Link>
              </div>
              <div className='header__hamburger'>
                <button
                  className='header__hamburger__button'
                  onClick={handleMenuClick}>
                  <div className='header__hamburger__bar'></div>
                  <div className='header__hamburger__bar'></div>
                  <div className='header__hamburger__bar'></div>
                </button>
              </div>
            </div>
            <div className={`header__menu ${menuOpen ? "open" : ""}`}>
              <Navigation closeMenu={closeMenu} />
            </div>
          </div>
        </Container>
      </div>
    </header>
  );
};

export default Header;
