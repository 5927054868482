import React from "react";
import { Link, useLocation } from "react-router-dom";

import "./Navigation.scss";

function Navigation({ closeMenu }) {
  const location = useLocation();
  const path = location.pathname;

  return (
    <nav className='navigation'>
      <ul className='navigation__list'>
        <li className='navigation__item'>
          <Link
            to='/sales-support'
            className={
              path === "/sales-support"
                ? "navigation__link active"
                : "navigation__link"
            }
            onClick={() => closeMenu()}
            aria-current={path === "/sales-support" ? "page" : undefined}>
            Sales Support
          </Link>
        </li>
        <li className='navigation__item'>
          <Link
            to='/operations-support'
            className={
              path === "/operations-support"
                ? "navigation__link active"
                : "navigation__link"
            }
            onClick={() => closeMenu()}
            aria-current={path === "/operations-support" ? "page" : undefined}>
            Operations Support
          </Link>
        </li>
        <li className='navigation__item'>
          <Link
            to='/conference-management'
            className={
              path === "/conference-management"
                ? "navigation__link active"
                : "navigation__link"
            }
            onClick={() => closeMenu()}
            aria-current={
              path === "/conference-management" ? "page" : undefined
            }>
            Conference Management
          </Link>
        </li>
        <li className='navigation__item'>
          <Link
            to='/signature-destinations'
            className={
              path === "/signature-destinations"
                ? "navigation__link active"
                : "navigation__link"
            }
            onClick={() => closeMenu()}
            aria-current={
              path === "/signature-destinations" ? "page" : undefined
            }>
            Signature Destinations
          </Link>
        </li>
        <li className='navigation__item'>
          <Link
            to='/video-monetization'
            className={
              path === "/video-monetization"
                ? "navigation__link active"
                : "navigation__link"
            }
            onClick={() => closeMenu()}
            aria-current={path === "/video-monetization" ? "page" : undefined}>
            Video Monetization
          </Link>
        </li>
        <li className='navigation__item'>
          <Link
            to='/award-events'
            className={
              path === "/award-events"
                ? "navigation__link active"
                : "navigation__link"
            }
            onClick={() => closeMenu()}
            aria-current={path === "/award-events" ? "page" : undefined}>
            Award Events
          </Link>
        </li>
        <li className='navigation__item'>
          <Link
            to='/contact-us'
            className={
              path === "/contact-us"
                ? "navigation__link active navigation__button"
                : "navigation__link navigation__button"
            }
            onClick={() => closeMenu()}
            aria-current={path === "/contact-us" ? "page" : undefined}>
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Navigation;
