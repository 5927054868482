import { Suspense, lazy, useState, useEffect } from "react";
import { useRoutes, useLocation } from "react-router-dom";

import "normalize.css";
import "./App.scss";
// import logo from './logo.svg';
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import ScrollTop from "./components/scroll-top/ScrollTop";
import NotFound from "./components/not-found/NotFound";
import CookieConsent from "react-cookie-consent";
import LoadingScreen from "./components/loading-screen/LoadingScreen";

import "./components/cookie-consent/CookieConsent.scss";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";

import AOS from "aos";
import "aos/dist/aos.css";

const Home = lazy(() => import("./pages/Home"));
const SalesSupport = lazy(() => import("./pages/SalesSupport"));
const OperationsSupport = lazy(() => import("./pages/OperationsSupport"));
const ConferenceManagement = lazy(() => import("./pages/ConferenceManagement"));
const SignatureDestinations = lazy(() =>
  import("./pages/SignatureDestinations")
);
const EventManagement = lazy(() => import("./pages/EventManagement"));
const PrivacyPolicy = lazy(() => import("./pages/legal/PrivacyPolicy"));
const TermsConditions = lazy(() => import("./pages/legal/TermsConditions"));
const CookiePolicy = lazy(() => import("./pages/legal/CookiePolicy"));
const VideoMonetisation = lazy(() => import("./pages/VideoMonetisation"));

function App() {
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState(location);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentLocation !== location) {
      setLoading(true);
      setTimeout(() => {
        setCurrentLocation(location);
        setLoading(false);
      }, 2000);
    }

    AOS.init({
      // You can also pass an optional settings object
      // below listed default settings
      offset: 200, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
    });
  }, [location, currentLocation]);

  let element = useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/sales-support",
      element: <SalesSupport />,
    },
    {
      path: "/operations-support",
      element: <OperationsSupport />,
    },
    {
      path: "/conference-management",
      element: <ConferenceManagement />,
    },
    {
      path: "/signature-destinations",
      element: <SignatureDestinations />,
    },
    {
      path: "/award-events",
      element: <EventManagement />,
    },
    {
      path: "/video-monetization",
      element: <VideoMonetisation />,
    },
    {
      path: "/about-us",
      element: <AboutUs />,
    },
    {
      path: "/contact-us",
      element: <Contact />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/terms-and-conditions",
      element: <TermsConditions />,
    },
    {
      path: "/cookie-policy",
      element: <CookiePolicy />,
    },
    { path: "*", element: <NotFound /> },
  ]);

  return (
    <div className='page'>
      <Header />
      <main className='main'>
        <ScrollTop />
        <CookieConsent
          overlay={true}
          location='bottom'
          buttonText='Accept'
          cookieName='redLemon'
          containerClasses='cookie-consent'
          contentClasses='cookie-consent__content'
          overlayClasses='cookie-consent__overlay'
          buttonWrapperClasses='cookie-consent__btn-wrapper'
          buttonClasses='cookie-consent__btn'
          expires={150}
          disableStyles={true}>
          This website uses cookies to enhance the user experience.
          <a href='/privacy-policy'>Learn more</a>
        </CookieConsent>
        <Suspense fallback={<LoadingScreen />}>
          {loading ? null : element}
        </Suspense>
      </main>
      <Footer />
      {loading && <LoadingScreen />}
    </div>
  );
}

export default App;
