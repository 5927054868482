import React from "react";
import Contacts from "../contact/Contacts";
import FooterNavigation from "../navigation/FooterNavigation";
import Container from "../../layout/container/Container";
import "./Footer.scss";

const Footer = () => {
  const date = new Date().getFullYear();
  return (
    <footer className='footer'>
      <Container>
        <div className='footer__wrapper'>
          <div className='footer__about'>
            <h2 className='footer__about__title'>About us</h2>
            <p className='footer__about__summary'>
              Red Lemon Events, delivers exceptional events globally,
              specializing in creating 'signature destinations' at established
              shows.
            </p>
          </div>
          <div className='footer__quicklinks'>
            <h2 className='footer__quicklinks__title'>Quick Links</h2>
            <FooterNavigation />
          </div>
          <div className='footer__contacts'>
            <h2 className='footer__contacts__title'>Contacts</h2>
            <Contacts exclude={["summary", "image"]} />
          </div>
        </div>
      </Container>
      <small className='footer__copyright'>
        &copy; {date} Red Lemon Events Ltd. All rights reserved.
      </small>
    </footer>
  );
};

export default Footer;
