import React from "react";
import "./LoadingScreen.scss";

const LoadingScreen = () => {
  return (
    <div className='load'>
      <div className='loading-spinner'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          id='Layer_1'
          x='0'
          y='0'
          version='1.1'
          viewBox='0 0 160 160'>
          <path
            fill='none'
            stroke='#c03c40'
            strokeMiterlimit='10'
            strokeWidth='5'
            className='loading-spinner__border'
            d='m152.6 29.6-1.7-2.9v-.1c-.1-.2-3-4.5-8-4.5-1.1 0-2.2.2-3.3.6-1.7.6-3.8.9-6.3.9-2 0-3.9-.2-5.1-.4-.8-.1-1.5-.4-2.2-.7-1.5-.7-4.3-2-8.1-3.4-6.2-2.1-14.9-4.3-24.9-4.3-3.9 0-7.7.3-11.4 1-5.5 1-10.2 2.2-14.1 3.3-12 3.6-24.3 12.5-31.1 19.2-6.8 6.6-16 17.2-21.4 30.8v.1c-.2.5-4.2 12-3.1 27.7.9 12.1.2 13.1-.4 13.9l-.1.1c-1.6 2.4-6.6 9.8-4.5 16.6.1.4 3.2 8.8 9.2 10.9 1.7.6 3.8.9 6.1.9 3.4 0 5.3-.3 6-.5.1 0 1.4-.3 1.8-.3 1.7 0 5.5.3 11.7 2.1l.8.2c3.5 1 6.3 1.9 9.2 2.5 4.1 1 8.2 1.5 14.4 1.7 2 .1 3.7.1 5.4.1 5 0 9.7-.4 15.2-1.8 4.1-1.1 8.6-2.7 13.9-5.1 15.9-7.3 22.4-11.1 29.6-19.1 6.8-7.6 13.3-14.9 16.6-29.9 3.3-14.5 1.4-30.3 1.3-31 0-.1-.9-6.8.5-11 1-2.9 2.7-6.1 3.8-7.9 1-1.8 1.6-3.9 1.5-6-.3-1.2-.6-2.5-1.3-3.7z'
          />
          <path
            d='M42.6 74.3v23.2h-6.3v-21zM48.1 57v6.6h-.4s-1.7.1-2.2.2c-.5.2-.9.4-2 1.5s-1 3.4-1 3.4v4.1L36.3 75V57.4l6.2-.1v3.5h.2c.2-.6.6-1.3.9-1.7.1-.1.2-.3.3-.4 1.6-1.8 4.2-1.7 4.2-1.7zM97.5 79.4c-5.5 0-9.9 4.1-9.9 9.2s4.4 9.2 9.9 9.2 9.9-4.1 9.9-9.2-4.5-9.2-9.9-9.2zm0 13.2c-2.1 0-3.8-1.8-3.8-4s1.7-4 3.8-4 3.8 1.8 3.8 4c-.1 2.2-1.8 4-3.8 4zM52.5 93.2c-2 0-2.8-.7-2.8-.7s-1.3-1-1.3-2.2h11.8v-1.7c0-5.8-3.8-9.1-8.8-9.1-4.3 0-7.9 3.1-8.8 7.3v3.8c.9 4.2 4.5 7.4 8.8 7.4 3.2 0 6-.9 8.1-3.6l-3.4-2.9c0-.1-1.6 1.7-3.6 1.7zM51.4 84c2.9 0 2.9 3.2 2.9 3.2h-5.9c-.1-1.4.9-3.2 3-3.2z'
            className='loading-spinner__text'
          />
          <path
            d='M64.8 66.3c0-.9.1-1.7.3-2.5-.9-4.4-4.3-6.8-8.5-6.8-3.9 0-7.2 2.5-8.5 6.1-.1.2-.1.4-.2.5-.2.8-.4 1.7-.4 2.6 0 5.1 4 9.3 9 9.3 3.2 0 6-.9 8.1-3.6L61.2 69s-1.6 1.9-3.6 1.9-2.8-.7-2.8-.7-1.3-1-1.3-2.2h11.4c-.1-.6-.1-1.2-.1-1.7zm-11.3-1.5c0-1.4 1-3.2 3-3.2 2.9 0 2.9 3.2 2.9 3.2h-5.9z'
            className='loading-spinner__text'
          />
          <path
            d='M78 53.2v6.2c-1.4-1.5-3.3-2.4-5.3-2.4-3.6 0-6.7 2.9-7.6 6.8-.2.8-.3 1.6-.3 2.5 0 .5 0 1.1.1 1.6.7 4.3 3.9 7.6 7.8 7.6 2.1 0 4-.9 5.4-2.5v2l6.2-2V51L78 53.2zm-3.4 17c-2.1 0-3.8-1.8-3.8-4s1.7-4 3.8-4c1.6 0 2.9 1 3.5 2.5v3c-.6 1.5-2 2.5-3.5 2.5zM106.6 97.4l.1-15.5 6.1-2v2.4s2.1-2.9 5-2.9c2.8 0 4 1.1 4 1.1s2.2 1.4 2.2 4v11l-6.1 1.9V87s-.2-2.1-2.6-2.1-2.5 2.2-2.5 2.2l-.1 8.3-6.1 2zM88.3 84.5v11l-6.2 1.9V87s0-1.9-2.4-1.9c-2.2 0-2.4 2.4-2.4 2.4l-.3 7.9-5.9 1.9-.3.1.2-10.1c0-1.3-.8-2.3-2.4-2.3-2.2 0-2.3 2-2.3 2v8.3l-6.3 2V81.9l6.1-2v2.4s2.1-2.9 5-2.9c2.8 0 4 1.1 4 1.1s.9.6 1.5 1.6c0 0 1.8-2.7 5.5-2.7 2.8 0 4 1.1 4 1.1s2.2 1.4 2.2 4z'
            className='loading-spinner__text'
          />
          <path d='M41.7 101.8h-4.8v2.7h4.3v.8h-4.3v2.7h4.8v.8H36V101h5.7v.8zM49.4 100.9h1l-3.4 7.9h-.8l-3.4-7.9h1l2.8 6.8 2.8-6.8zM57.6 101.8h-4.8v2.7h4.3v.8h-4.3v2.7h4.8v.8h-5.7V101h5.7v.8zM65.2 100.9h.8v7.8h-.7l-5.1-6.4v6.4h-.9v-7.8h.8l4.9 6.3.2-6.3zM71.2 108.8h-.9v-7h-2.6v-.8h6.1v.8h-2.6v7zM76.1 103.4c.1.1.2.3.3.4l.6.3c.2.1.6.2 1.1.3.9.2 1.5.5 1.9.8s.6.8.6 1.4c0 .3-.1.6-.2.9-.1.3-.3.5-.5.7-.2.2-.5.3-.8.5-.3.1-.7.2-1.1.2-.6 0-1.2-.1-1.7-.3-.5-.2-1-.5-1.5-.9l.5-.6c.4.4.8.6 1.2.8.4.2.9.3 1.5.3.5 0 1-.1 1.3-.4s.5-.6.5-1c0-.2 0-.4-.1-.5-.1-.1-.2-.3-.3-.4-.1-.1-.4-.2-.6-.3-.3-.1-.6-.2-1-.3-.5-.1-.8-.2-1.2-.3-.3-.1-.6-.3-.8-.5-.2-.2-.4-.4-.5-.6s-.2-.5-.2-.9c0-.3.1-.6.2-.9.1-.3.3-.5.5-.7.2-.2.5-.3.8-.5.3-.1.7-.2 1-.2.6 0 1.1.1 1.5.2.4.2.8.4 1.2.7l-.5.7c-.4-.3-.7-.5-1.1-.6-.4-.1-.8-.2-1.2-.2-.3 0-.5 0-.7.1-.2.1-.4.2-.5.3-.1.1-.3.2-.3.4-.1.2-.1.3-.1.5.1.3.2.4.2.6z' />
        </svg>
      </div>
    </div>
  );
};

export default LoadingScreen;
