import React from "react";
import PageTitle from "../components/page-title/PageTitle";
import Container from "../layout/container/Container";
// import ImageGroup from "../components/image-group/ImageGroup";
import ContentGroup from "../components/content-group/ContentGroup";
// import Label from "../components/label/Label";
import Button from "../components/button/Button";
import Metatags from "../components/metatags/Metatags";
import Contacts from "../components/contact/Contacts";

function AboutUs() {
  return (
    <div>
      <Metatags title='Contact' />
      <PageTitle title='Contact' />
      <section
        id='contacts'
        className='contacts'
        data-aos='fade-up'
        data-aos-delay='300'>
        <Container>
          <ContentGroup width={"full"}>
            <h2 className='content-group__title text_center'>
              Want to get in touch?
            </h2>
            <p className='content-group__summary text_center'>
              Reach out to either of us using the contact details below:
            </p>
          </ContentGroup>
          <Contacts
            isList={true}
            className='contact__wrapper_about'
            exclude={"summary"}
          />
        </Container>
      </section>
    </div>
  );
}

export default AboutUs;
