import React from "react";
import PageTitle from "../components/page-title/PageTitle";
import Container from "../layout/container/Container";
// import ImageGroup from "../components/image-group/ImageGroup";
import ContentGroup from "../components/content-group/ContentGroup";
// import Label from "../components/label/Label";
import Button from "../components/button/Button";
import Metatags from "../components/metatags/Metatags";
import Contacts from "../components/contact/Contacts";

function AboutUs() {
  return (
    <div>
      <Metatags title='About us' />
      <PageTitle title='About us' />
      <section
        className='page__intro page__intro_grey'
        data-aos='fade-up'
        data-aos-delay='300'>
        <Container width='small'>
          <ContentGroup width='full'>
            <p className='content-group__summary'>
              The team at Red Lemon Events - headed by Ben Gill and Neil Nixon -
              is well known across the events community, having worked globally
              to deliver{" "}
              <a href='/conference-management'>conferences and exhibitions</a>{" "}
              in industries including transport, infrastructure, retail,
              facilities management and, most recently, broadcast technologies.
            </p>
            <p className='content-group__summary'>
              Experienced in <a href='/sales-support'>sales</a> and{" "}
              <a href='/operations-support'>content curation</a>, the team at
              Red Lemon Events has an expertise in developing and delivering
              'signature destinations' - events within events - at established
              shows for organisers of all sizes. In recent years this has
              included working with both IBC and NAB Show to develop
              multi-million-pound attractions. With over 50 years of combined
              experience in exhibition and{" "}
              <a href='/event-management'>
                conference development and delivery
              </a>
              , Ben and Neil are recognised for delivering outstanding events
              that exceed exhibitor and visitor expectations.
            </p>
            <p className='about__link'>
              <Button
                href='#contacts'
                colour='primary'
                type='fill'
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .getElementById("contacts")
                    .scrollIntoView({ behavior: "smooth" });
                }}>
                Contact us
              </Button>
            </p>
          </ContentGroup>
        </Container>
      </section>

      <section
        id='contacts'
        className='contacts'
        data-aos='fade-up'
        data-aos-delay='300'>
        <Container>
          <Contacts isList={false} className='contact__wrapper_about' />
        </Container>
      </section>
    </div>
  );
}

export default AboutUs;
