import React from "react";
import "./Contact.scss";

const Contact = ({ contacts, exclude, className, isList }) => {
  const formatContactNumber = (contactNumber) => {
    return contactNumber.slice(3, contactNumber.length).replace(/^/, "+44 (0)");
  };

  const Wrapper = isList ? "ul" : "div";
  const ItemWrapper = isList ? "li" : "div";
  const isListClass = isList ? "contact__list" : "contact__wrapper";

  return (
    <>
      {contacts.length > 0 && (
        <Wrapper className={`${isListClass} ${className}`}>
          {contacts.map(({ id, name, summary, image, contact }) => (
            <ItemWrapper key={id} className='contact__item'>
              {!exclude.includes("image") && (
                <div className='contact__thumbnail'>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/contacts/${image}`}
                    alt={name}
                    className='contact__image'
                  />
                </div>
              )}
              <div className='contact__details'>
                <h3 className='contact__name'>{name}</h3>
                {!exclude.includes("summary") && (
                  <p className='contact__summary'>{summary}</p>
                )}
                {!exclude.includes("email") && (
                  <a
                    href={`mailto:${contact?.email}`}
                    className='contact__email'>
                    {contact?.email}
                  </a>
                )}
                {!exclude.includes("phone") && (
                  <a
                    href={`tel:${contact?.number}`}
                    className='contact__number'>
                    {formatContactNumber(contact?.number)}
                  </a>
                )}
                {!exclude.includes("linkedin") && (
                  <a href={contact?.linkedin} className='contact__linkedin'>
                    Connect on LinkedIn
                  </a>
                )}
              </div>
            </ItemWrapper>
          ))}
        </Wrapper>
      )}
    </>
  );
};

export default Contact;

// The `exclude` prop is an optional array of string values that represent the contact properties to be excluded from rendering. If `exclude` is not passed as a prop, it will be assigned a default value of an empty array.
