import React from "react";
import "./Container.scss";

const Container = ({ width, children }) => {
  let containerWidth;

  // Return a 'container' class only when no width is specified
  if (!width) {
    return <div className='container'>{children}</div>;
  }

  switch (width) {
    case "xsmall":
      containerWidth = "container_xsmall";
      break;
    case "small":
      containerWidth = "container_small";
      break;
    case "large":
      containerWidth = "container_large";
      break;
    case "full":
      containerWidth = "container_full";
      break;
    default:
      containerWidth = "";
      break;
  }

  return <div className={`container ${containerWidth}`}>{children}</div>;
};

export default Container;
