import React from "react";
import Button from "../button/Button";

import "./NotFound.scss";

const NotFound = () => {
  return (
    <section className='not-found'>
      <div className='not-found__wrapper'>
        <h1 className='not-found__title'>Page not found</h1>
        <p className='not-found__details'>
          Sorry, the page you are looking for does not exist.
        </p>
        <Button href='/' colour='white' type='outline'>
          Back to site
        </Button>
      </div>
    </section>
  );
};

export default NotFound;
