// import React from "react";
// import PropTypes from "prop-types";
// import "./Button.scss";

// const Button = ({
//   href,
//   target,
//   rel,
//   onClick,
//   children,
//   colour = "primary",
//   type = "fill",
//   className = "",
// }) => {
//   return (
//     <a
//       href={href}
//       target={target}
//       rel={rel}
//       onClick={onClick}
//       className={`btn btn_${colour} btn_${type} ${className ? className : ""}`}>
//       {children}
//     </a>
//   );
// };

// Button.propTypes = {
//   href: PropTypes.string.isRequired,
//   target: PropTypes.string,
//   rel: PropTypes.string,
//   onClick: PropTypes.func,
//   children: PropTypes.node.isRequired,
//   type: PropTypes.string,
// };

// Button.defaultProps = {
//   type: "primary",
// };

// export default Button;

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom"; // <- import the Link component
import "./Button.scss";

const Button = ({
  href,
  target,
  rel,
  onClick,
  children,
  colour = "primary",
  type = "fill",
  className = "",
}) => {
  return (
    <Link
      to={href}
      target={target}
      rel={rel}
      onClick={onClick}
      className={`btn btn_${colour} btn_${type} ${className ? className : ""}`}>
      {children}
    </Link>
  );
};

Button.propTypes = {
  href: PropTypes.string.isRequired,
  target: PropTypes.string,
  rel: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
};

Button.defaultProps = {
  type: "primary",
};

export default Button;
