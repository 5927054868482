export const contactData = [
  {
    id: 1,
    name: "Neil Nixon",
    image: "neil-nixon.jpg",
    summary: ["With a background in trade and national newspaper journalism, Neil has been involved in the events sector for almost 40 years in industries as diverse as marine transportation, utilities, retail, automotive, facilities management, and broadcast. A serial entrepreneur, Neil has been central to numerous launches and new developments within established events and was part of the team responsible for the Connected Media attractions at both IBC and NAB Show. Neil has produced many international conferences for the global FM sector and has also been an organiser and judge across a number of award programmes, working with global brands on international events. Alongside Ben Gill, Neil launched and ran the Connected Media IP area of NAB Show for 10 years. Neil is currently co-owner and director of Red Lemon Events Ltd and co-producer of the Corporate Star Awards."],
    contact: {
      number: "+447957713316",
      email: "nnixon@redlemonevents.co.uk",
      linkedin: "https://www.linkedin.com/in/neil-nixon-66988660/",
    },
  },
  {
    id: 2,
    name: "Ben Gill",
    image: "ben-gill.jpg",
    summary: ["With almost 15 years event industry experience, Ben has been responsible for business development and client relationship management across some of the world's leading trade shows, including IBC and NAB Show. A creative strategist, Ben has overseen the introduction and development of a number of events and attractions that reflect the changing requirements of industry professionals. In addition to his roles in event development, Ben was also responsible for the successful launch of the Connected World. TV Awards @ IBC, working with renowned film director James Cameron in delivering the inaugural event. Alongside Neil Nixon, Ben launched and ran the Connected Media IP area of NAB Show for 10 years. Ben is currently co-owner and director of Red Lemon Events Ltd and co-producer of the Corporate Star Awards."],
    contact: {
      number: "+447972676057",
      email: "bgill@redlemonevents.co.uk",
      linkedin: "https://www.linkedin.com/in/bengill1",
    },
  },
];