import React from "react";
import "./ContentGroup.scss";

const ContentGroup = ({ width, className, children }) => {
  let contentWidth = width;
  const additionalClasses = className ? className : "";

  // Return a 'container' class only when no width is specified
  if (!width) {
    return (
      <div
        className={`content-group content-group__wrapper_default ${additionalClasses}`}>
        <div className={`content-group__wrapper`}>{children}</div>
      </div>
    );
  }

  switch (width) {
    case "small":
      contentWidth = "small";
      break;
    case "medium":
      contentWidth = "medium";
      break;
    case "large":
      contentWidth = "wide";
      break;
    case "full":
      contentWidth = "full";
      break;
    default:
      contentWidth = "default";
      break;
  }

  return (
    <div
      className={`content-group ${additionalClasses} content-group__wrapper_${contentWidth}`}>
      <div className={`content-group__wrapper`}>{children}</div>
    </div>
  );
};

export default ContentGroup;
