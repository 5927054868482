import React from "react";
import Contact from "./Contact";
import { contactData } from "./ContactData";

const Contacts = ({ exclude = [], className, isList = true }) => {
  return (
    <Contact
      className={className}
      isList={isList}
      exclude={exclude}
      contacts={contactData}
    />
  );
};

export default Contacts;
